<template>
  <div class="access-permission-container">
    <vs-row class="mt-5">
      <vs-card class="fix-pads">
        <div slot="header">
          <div class="flex justify-between items-center">
            <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Users</h3>
          </div>
        </div>
        <div class="flex justify-end">
          <vs-button
            class="only-border-btn"
            size="medium"
            v-round
            @click="$router.push({ name: 'users-create' })"
          >
            <plus-icon size="1.5x" class="button-icon"></plus-icon>
            New User</vs-button
          >
        </div>
        <vs-table :data="users" class="mt-8 md:mt-10 lg:mt-12 stripes merch-list">
          <template slot="thead">
            <vs-th> Name </vs-th>
            <vs-th> Role </vs-th>
            <vs-th> Email </vs-th>
            <vs-th> </vs-th>
            <vs-th> </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(user, indextr) in data">
              <vs-td :data="user.title">
                {{ user.name ? user.name : user.systemName }}
              </vs-td>
              <vs-td :data="user.userType">
                <span v-if="user.userType == 'admin'">Admin</span>
                <span v-if="user.userType == 'apiUser'">API User</span>
                <span v-if="user.userType == 'user'">User</span>
              </vs-td>
              <vs-td :data="user.reference">
                {{ user.email ? user.email : "N/A" }}
              </vs-td>
              <vs-td :data="user">
                <a
                  v-if="
                    user.userType != 'apiUser' &&
                    user.introducerId &&
                    user.userType != 'introducerUser'
                  "
                  @click="sendCredentials(user._id)"
                  ><u>{{
                    user.credentialsSent
                      ? "Resend credentials"
                      : "Send credentials"
                  }}</u></a
                >
              </vs-td>

              <vs-td :data="user._id">
                <a @click="viewUser(user._id)"><u>Edit</u></a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div>
          <vs-row class="px-2 mt-8 mb-6 flex justify-center sm:justify-between items-center">
            <div class="flex mb-6 sm:mb-0 items-center gap-4">
              <span class="text-base">Records per page</span>
              <vs-select v-model="limit" class="records-per-page">
                <vs-select-item
                  v-for="(item, index) in limitValue"
                  :key="index"
                  class=""
                  :text="item.text"
                  :value="item.value"
                />
              </vs-select>
            </div>

            <v-pagination v-model="pageNumber" :page-count="totalPage"></v-pagination>
          </vs-row>
        </div>
      </vs-card>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { EditIcon, PlusIcon } from "vue-feather-icons";
import vPagination from "@/views/components/pagination";

export default {
  components: {
    EditIcon,
    PlusIcon,
    vPagination,
  },
  data() {
    return {
      users: [],
      pageNumber: 1,
      limit: 50,
      totalData: 0,
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    };
  },
  watch: {
    page(val) {
      this.getUsers();
    },
    limit(val) {
      this.getUsers();
    },
  },
  methods: {
    ...mapActions("user", ["fetchUsers", "sendCredentialsToUser"]),

    async sendCredentials(id) {
      await this.sendCredentialsToUser(id)
        .then((result) => {
          this.$vs.notify({
          title: "Success",
          text: "The username and password has been successfully sent!",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
          this.getUsers(this.introducerId);
        })
        .catch((err) => {});
    },

    // functions of users
    async getUsers() {
      const data = {
        introducerId: this.introducerId,
        options: `?page=${this.page}&limit=${this.limit}`,
      };
      await this.fetchUsers(data)
        .then((result) => {
          this.users = result.data.data.docs;
          this.totalData = result.data.data.totalDocs;
        })
        .catch((err) => {});
    },
    viewUser(id) {
      this.$router.push({
        name: "user-edit",
        params: { id: id },
      });
    },
  },

  mounted() {
    this.getUsers();
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    introducerId() {
      return this.user.userType === "superAdmin"
        ? this.user._id
        : this.user.introducer;
    },
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
};
</script>